import React, { useState, useEffect } from 'react';

import Bead from './Bead';

import '../../css/AbacusAssets/main.css';
import compArr from '../core/compArr';

export default function AnimUpper(props) {
    const [top, upTop] = useState(props.start[0]);
    const [bot, upBot] = useState(props.start[1]);
    const [doneBefore, upDB] = useState(false);

    useEffect(() => {
        upTop(props.start[0]);
        upBot(props.start[1]);
    }, [props.start])


    const cb = props.callback;
    useEffect(() => {
        if (!doneBefore && props.run) {
            upDB(true);
            if (!compArr(props.start, props.end)) {
                setTimeout(() => {
                    upDB(true);
                    upTop(props.end[0]);
                    upBot(props.end[1]);
                    if (typeof cb === "function") cb()
                }, 1000)
            }
            else if (typeof cb === "function") cb();
        }
    }, [cb, props.start, props.end, props.run, doneBefore])

    return (
        <div className="upper" >
            <div className="verticalLine"></div>
            <Bead enabled={top} />
            <Bead enabled={bot} />
        </div>
    )
}