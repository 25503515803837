import React from 'react';
import {
    Link
} from 'react-router-dom';
import lang from './core/lang';

import '../css/core.css';
import '../css/navbar.css';

export default class Navbar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{zIndex: "3"}} >
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarDropdown" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div>
                    <Link className="navbar-brand" to="/" >{lang("navbar_home")}</Link>
                </div>
                <div className="navbar-collapse collapse bg-light" id="navbarDropdown" style={{zIndex: "4"}} >
                    <ul className="navbar-nav mr-auto" >
                        <li className="nav-item" >
                            <Link className="nav-link" to="/gennum" >{lang("navbar_gennum")}</Link>
                        </li>
                        <li className="nav-item" >
                            <Link className="nav-link" to="/anim" >{lang("navbar_visualize")}</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}