import React from 'react';
import Bead from './Bead';

export default function ClickBead(props)
{
    var newProps = {...props}
    if (newProps.enabled) newProps.className = newProps.className ? newProps.className + " bead" : "bead";


    return (
        <Bead {...newProps} />
    )
}