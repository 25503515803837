import React from 'react';

import Upper from './Upper';
import Divider from './Divider';
import Lower from './Lower';

export default class Unit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dUpper: [true, false],
            dLower: [false, true, true, true, true],
            currentNum: this.props.displayNum,
        }

        this.upClick = this.upClick.bind(this);
        this.lowClick = this.lowClick.bind(this);
    }

    componentDidMount() {
        var remainingNum = this.state.currentNum;

        if (remainingNum >= 5) {
            this.setState({ dUpper: [false, true] });
            remainingNum -= 5;
        }
        else this.setState({ dUpper: [true, false] });

        if (remainingNum === 4) {
            this.setState({ dLower: [true, true, true, true, false] })
        }
        else if (remainingNum === 3) {
            this.setState({ dLower: [true, true, true, false, true] })
        }
        else if (remainingNum === 2) {
            this.setState({ dLower: [true, true, false, true, true] })
        }
        else if (remainingNum === 1) {
            this.setState({ dLower: [true, false, true, true, true] })
        }
        else if (remainingNum === 0) {
            this.setState({ dLower: [false, true, true, true, true] });
        }
    }


    render() {

        return (
            <div style={{ display: "block", width: "100px" }} >
                <Divider />
                <Upper display={this.state.dUpper} onClick={this.upClick} interactive />
                <Divider />
                <Lower display={this.state.dLower} onClick={this.lowClick} interactive />
                <Divider />
            </div>
        )
    }

    upClick() {
        if (typeof this.props.numChange === 'function') {
            this.props.numChange(this.state.currentNum + 5)
        }

        this.setState({ currentNum: this.state.currentNum >= 5 ? this.state.currentNum - 5 : this.state.currentNum + 5 }, this.componentDidMount);
    }

    lowClick(id, enabled) {

        var fin = this.componentDidMount;

        if (enabled) {
            var to;

            switch (id) {
                case 0:
                    if (this.state.currentNum < 5) {
                        this.setState({ currentNum: 0 }, fin);
                    }
                    else this.setState({ currentNum: 5 }, fin);
                    break;
                case 1:
                    to = 0;
                    if (this.state.dLower[0]) {
                        for (to = 1; this.state.dLower[to] === true; to++);
                        to = -to + 1;
                    }
                    else {
                        to = +1;
                    }

                    this.setState({ currentNum: this.state.currentNum + to }, fin);
                    break;
                case 2:
                    to = 0;
                    switch (this.state.dLower.toString()) {
                        case [true, true, true, true, false].toString():
                            to = -2;
                            break;
                        case [true, true, true, false, true].toString():
                            to = -1;
                            break;
                        case [true, false, true, true, true].toString():
                            to = 1;
                            break;
                        case [false, true, true, true, true].toString():
                            to = 2;
                            break;
                        default:
                            break;
                    }

                    this.setState({ currentNum: this.state.currentNum + to }, fin);
                    break;
                case 3:
                    if (this.state.dLower[4]) {
                        for (to = 0; this.state.dLower[3 - to] === true; to++);
                    }
                    else to = -1;

                    this.setState({ currentNum: this.state.currentNum + to }, fin);
                    break;
                case 4:
                    if (this.state.currentNum < 5) {
                        this.setState({ currentNum: 4 }, fin)
                    } else this.setState({ currentNum: 9 }, fin);
                    break;
                default:
                    break;
            }
        }
    }
}