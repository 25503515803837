import React, { useState, useEffect } from 'react';

import Bead from './Bead';

import '../../css/AbacusAssets/main.css';
import compArr from '../core/compArr';

//hehe haha hooo hooo


export default function AnimLower(props) {
    const [current, upC] = useState(props.start);
    const [doneBefore, upDB] = useState(false);

    useEffect(() => {
        upC(props.start);
    }, [props.start])

    const { callback, run, end } = props;
    const check = !compArr(props.start, props.end);
    useEffect(() => {
        if (!doneBefore && run) {
            upDB(true);
            if (check) {
                setTimeout(() => {
                    upC(end);

                    if (typeof callback === "function") callback();
                }, 1000)
            }
            else if (typeof callback === "function") callback();
        }

    }, [doneBefore, callback, run, check, end, props.index])

    return (
        <div className="lower" >
            <div className="verticalLine" ></div>
            <Bead enabled={current[0]} />
            <Bead enabled={current[1]} />
            <Bead enabled={current[2]} />
            <Bead enabled={current[3]} />
            <Bead enabled={current[4]} />
        </div>
    )
}