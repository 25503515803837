import React from 'react';

import noImg from  '../../img/80x40transp.svg';
import beadImg from '../../img/bead.svg';
import darkImg from '../../img/beadTrans.svg';

import '../../css/AbacusAssets/main.css';
import '../../css/core.css';

export default class Bead extends React.Component {
    render() {
        var newProps = {...this.props};


        if (newProps.enabled) delete newProps.enabled;
        if (newProps.alt) delete newProps.alt;

        newProps.className = newProps.className ? newProps.className + " no-img-drag" : "no-img-drag";

        return (
            <div>
                <img src={this.props.enabled ? this.props.dark ? darkImg : beadImg : noImg} alt={this.props.enabled ? "Bead" : "Nothing"} width="100%" height="100%" {...newProps} />
            </div>
        )
    }
}