import React from 'react';

export default class Spaces extends React.Component {
    render()
    {
        var out = [];
        for (var i = 0; i < this.props.size ? this.props.size : 0; i++, out.push(<React.Fragment key={i} >&nbsp;</React.Fragment>));

        return out;
    }
}