import React from 'react';

import Spaces from './core/Spaces';
import random from './core/random';
import lang from './core/lang';
import Abacus from './Abacus';

import '../css/core.css';
import '../css/genNum.css';




export default class GenNum extends React.Component {


    constructor(...stuff) {
        super(...stuff);

        this.state = {
            qDur: "",
            dPlace: 5,
            displayNum: 0,
            prevAns: "",
        }

        this.stop = false;
        this.processes = [];

        this.timeout = null;
    }

    render() {
        console.log(this.state.temp);
        return (
            <div>
                <div className="w-100 h-75" >
                    <Abacus size={0} displayNum={this.state.displayNum} interactive={false} />
                </div>

                <div className="dataFill noselect" >
                    <div className="align-self-center md-up" >{lang("gennum_qdur")}:</div>
                    <div><Spaces size={2} className="md-up" /></div>
                    <div><input type="number" className="form-control noNumArrow md-up" value={this.state.qDur} onKeyDown={key => { if (key.keyCode === 69) key.preventDefault() }} onChange={evt => this.inputUpdate(evt, "qDur")} style={{ textAlign: "center" }} /></div>

                    <div><Spaces size={2} className="md-up" /></div>

                    <div className="align-self-center md-up" >{lang("gennum_dplace")}:</div>
                    <div><Spaces size={2} className="md-up" /></div>
                    <div><input type="number" className="form-control noNumArrow md-up" value={this.state.dPlace} onKeyDown={key => { if (key.keyCode === 69) key.preventDefault() }} onChange={evt => this.inputUpdate(evt, "dPlace")} style={{ textAlign: "center" }} /></div>

                    <div><Spaces size={2} className="md-up" /></div>

                    <button className="btn btn-primary md-up" onClick={() => this.start()} >{lang("gennum_update")} </button>
                    <Spaces size={2} className="md-up" />
                    <button className="btn btn-primary md-up" onClick={() => this.stopInterval()}>{lang("gennum_stop")}</button>

                    <div className="md-up align-self-center ml-3" >
                        {this.state.prevAns}
                    </div>
                </div>
                <div className="dataFill noselect custom">
                    <div className="align-self-center sm-only" >{lang("gennum_qdur")}:</div>
                    <div><Spaces size={2} className="sm-only" /></div>
                    <input type="number" className="form-control noNumArrow sm-only" value={this.state.qDur} onKeyDown={key => { if (key.keyCode === 69) key.preventDefault() }} onChange={evt => this.inputUpdate(evt, "qDur")} style={{ textAlign: "center" }} />
                </div>
                <div className="dataFill noselect custom" >
                    <div className="align-self-center sm-only" >{lang("gennum_dplace")}:</div>
                    <div><Spaces size={2} className="sm-only" /></div>
                    <input type="number" className="form-control noNumArrow sm-only" value={this.state.dPlace} onKeyDown={key => { if (key.keyCode === 69) key.preventDefault() }} onChange={evt => this.inputUpdate(evt, "dPlace")} style={{ textAlign: "center" }} />
                </div>
                <div className="dataFill noselect" >
                    <button className="btn btn-primary sm-only bigButton" onClick={() => this.start()} >{lang("gennum_update")} </button>
                    <Spaces size={2} className="sm-only bigButton" />
                    <button className="btn btn-primary sm-only bigButton" onClick={() => this.stopInterval()}>{lang("gennum_stop")}</button>
                    <div className="sm-only" >
                        {this.state.prevAns}
                    </div>
                </div>
            </div>
        )
    }

    inputUpdate(evt, id) {
        if (!isNaN(Number(evt.target.value))) {
            this.setState({ [id]: !evt.target.value ? "" : Number(evt.target.value) });
        }
    }

    start() {
        if (this.state.qDur !== "" && this.state.dPlace !== "" && this.state.qDur !== 0 && this.state.dPlace !== 0) {
            this.dPlace = this.state.dPlace;
            this.qDur = this.state.qDur;
            this.stop = false;
            clearTimeout(this.timeout);
            this.updateNumber();
        }
    }

    stopInterval() {
        this.stop = true;
    }

    updateNumber() {
        if (!this.stop) {
            this.setState(
                prevState => {
                    return {
                        displayNum: random(Math.pow(10, this.dPlace - 1), Math.pow(10, this.dPlace)),
                        prevAns: prevState.displayNum,
                    }
                },
                () => { this.timeout = setTimeout(() => this.updateNumber(), this.qDur * 1000) }
            );
        }
    }
}