import React, { useState, useEffect } from 'react';

import AnimUpper from './AnimUpper';
import Divider from './Divider';
import AnimLower from './AnimLower';


export default function AnimUnit(props) {
    const [uStart, upUS] = useState([true, false]);
    const [uEnd, upUE] = useState([true, false]);
    const [lStart, upLS] = useState([false, true, true, true, true]);
    const [lEnd, upLE] = useState([false, true, true, true, true]);
    const [topStart, upT] = useState(false);
    const [botStart, upB] = useState(false);
    const [trig, upTr] = useState(false);
    const [up2, upT2] = useState(false);
    const [td, upTD] = useState(false);

    const abc = typeof props.callback === "function";
    const cb = props.callback;

    useEffect(() => {
        var sRemNum = props.start;

        if (sRemNum >= 5) {
            if (props.current <= props.index) upUS([false, true])
            if (props.current < props.index) upUE([false, true]);
            sRemNum -= 5;
        }

        if (sRemNum === 4) {
            if (props.current <= props.index) upLS([true, true, true, true, false])
            if (props.current < props.index) upLE([true, true, true, true, false])
        }
        else if (sRemNum === 3) {
            if (props.current <= props.index) upLS([true, true, true, false, true]);
            if (props.current < props.index) upLE([true, true, true, false, true])

        }
        else if (sRemNum === 2) {
            if (props.current <= props.index) upLS([true, true, false, true, true]);
            if (props.current < props.index) upLE([true, true, false, true, true])
        }
        else if (sRemNum === 1) {
            if (props.current <= props.index) upLS([true, false, true, true, true]);
            if (props.current < props.index) upLE([true, false, true, true, true])
        }

        if (props.index === props.current) {
            upT(true);

            var eRemNum = props.end;

            if (eRemNum >= 5) {
                upUE([false, true]);
                eRemNum -= 5;
            }
            else if (eRemNum < 5) {
                upUE([true, false]);
            }

            if (eRemNum === 4) {
                upLE([true, true, true, true, false]);
            }
            else if (eRemNum === 3) {
                upLE([true, true, true, false, true]);
            }
            else if (eRemNum === 2) {
                upLE([true, true, false, true, true]);
            }
            else if (eRemNum === 1) {
                upLE([true, false, true, true, true]);
            }
            else if (eRemNum === 0) {
                upLE([false, true, true, true, true]);
            }
        }

    }, [props]);

    useEffect(() => {
        if (up2 && abc && !trig) {
            upTr(true);
            cb();
        }
    }, [up2, abc, trig, cb, props.index])

    function topDone() {
        upTD(true);
        upT(false);
        upB(true);
    }

    function botDone() {
        if (td) {
            upT(false);
            upB(false);
            upT2(true);
        }
    }

    return (
        <div style={{ display: "block", width: "100px" }} >
            <Divider />
            <AnimUpper start={uStart} end={uEnd} callback={() => topDone()} run={topStart} index={props.index} />
            <Divider />
            <AnimLower start={lStart} end={lEnd} callback={() => botDone()} run={botStart} index={props.index} />
            <Divider />
        </div>
    )
}