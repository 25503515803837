import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import GenNum from './jsx/GenNum';
import Navbar from './jsx/Navbar';
import Anim from './jsx/Anim';

import wechat from './img/wechat.jpg';

import './css/core.css';

/*ReactDOM.render(
  <React.StrictMode>
    <Abacus size={abSize && !isNaN(abSize) ? abSize : 1} interactive={interactive} displayArray={displayArray} />
  </React.StrictMode>,
  document.getElementById('root')
);*/

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Switch>
        <Route path="/anim" component={Anim} />
        <Route path="/gennum" component={GenNum} />
        <Route path="/" component={GenNum} />
      </Switch>

      <div className="noselect text-center" >
        <img style={{ width: "125px", height: "125px" }} className="no-img-drag" alt="wechat" src={wechat} />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);