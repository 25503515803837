import React from 'react';

import AnimAbacus from './AnimAbacus';
import Abacus from './Abacus';
import Spaces from './core/Spaces';
import lang from './core/lang';

import '../css/core.css';
import '../css/anim.css';

export default class Anim extends React.Component {

    state = {
        opFunc: (x, y) => { return x + y },
        op: "+",
        no1: "",
        no2: "",
        render: false,
        disabled: false,
        tempEnd: 0,
    }

    render() {
        return (
            <div>
                {this.state.render ? <AnimAbacus start={Number(this.state.no1)} end={this.state.opFunc(Number(this.state.no1), Number(this.state.no2))} render={this.state.render} callback={() => this.finish()} />
                    : <Abacus displayNum={this.state.tempEnd} />}
                <Spaces size={2} />
                <div className="d-flex operBox" >
                    <div className="d-flex" >
                        <div>
                            <button className="btn btn-primary" onClick={() => this.setOp("+")} >
                                +
                            </button>
                        </div>
                        <Spaces size={1} />
                        <div>
                            <button className="btn btn-primary" onClick={() => this.setOp("-")} >
                                -
                            </button>
                        </div>
                        <Spaces size={1} />
                        <div>
                            <button className="btn btn-primary" onClick={() => this.setOp("x")} >
                                x
                            </button>
                        </div>
                        <Spaces size={1} />
                        <div>
                            <button className="btn btn-primary" onClick={() => this.setOp("/")} >
                                /
                            </button>
                        </div>
                    </div>
                    <div className="w-100 no2" >
                        <input type="number" onKeyDown={key => { if (key.keyCode === 69 || key.keyCode === 190) key.preventDefault() }} value={this.state.no1} onChange={evt => this.inputUpdate(evt, "no1")} disabled={this.state.disabled} className="form-control noNumArrow text-center w-100" />
                    </div>
                    <div className="no2 d-flex w-100" >
                        <div className="flexAlignCenter textCenter" >
                            {this.state.op}
                        </div>
                        <Spaces size={2} />
                        <input type="number" onKeyDown={key => { if (key.keyCode === 69 || key.keyCode === 190) key.preventDefault() }} value={this.state.no2} onChange={evt => this.inputUpdate(evt, "no2")} disabled={this.state.disabled} className="form-control noNumArrow text-center w-100" />
                        <Spaces size={2} />
                        <button className="btn btn-primary md-up" onClick={() => this.loadAnim()} >{lang("anim_start")}</button>
                    </div>
                    <div className="no2 w-100" >
                        <button className="btn btn-primary sm-only" onClick={() => this.loadAnim()}>{lang("anim_start")}</button>
                    </div>
                </div>
            </div>
        );
    }

    setOp(op) {
        var opFunc;
        switch (op) {
            case "+":
                opFunc = (x, y) => { return x + y }
                break;
            case "-":
                opFunc = (x, y) => { return x - y }
                break;
            case "x":
                opFunc = (x, y) => { return x * y }
                break;
            case "/":
                opFunc = (x, y) => { return x / y }
                break;
            default: break;
        }

        this.setState({ opFunc: opFunc, op: op });
    }

    inputUpdate(evt, id) {
        if (!isNaN(Number(evt.target.value))) {
            this.setState({ [id]: !evt.target.value ? "" : Number(evt.target.value) });
        }
    }

    loadAnim() {
        if ((this.state.op === "-" || this.state.op === "/") && (this.state.no1 < this.state.no2)) return;

        this.setState({ render: true, disabled: true })
    }

    finish() {
        this.setState(prev => {
            return { disabled: false, render: false, tempEnd: prev.opFunc(Number(prev.no1), Number(prev.no2)) }
        })
    }
}