import React from 'react';

import Upper from './Upper';
import Divider from './Divider';
import Lower from './Lower';

export default function Unit(props) {
    var remainingNum = props.displayNum;

    var dUpper = [true, false];
    var dLower = [false, true, true, true, true];

    if (remainingNum >= 5) {
        dUpper = [false, true];
        remainingNum -= 5;
    }

    if (remainingNum === 4) {
        dLower = [true, true, true, true, false];
    }
    else if (remainingNum === 3) {
        dLower = [true, true, true, false, true];
    }
    else if (remainingNum === 2) {
        dLower = [true, true, false, true, true];
    }
    else if (remainingNum === 1) {
        dLower = [true, false, true, true, true];
    }

    return (
        <div style={{ display: "block", width: "100px" }} >
            <Divider />
            <Upper display={dUpper} />
            <Divider />
            <Lower display={dLower} />
            <Divider />
        </div>
    )
}