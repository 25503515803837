import React from 'react';

import Bead from './Bead';
import ClickBead from './ClickBead';

import '../../css/AbacusAssets/main.css';

export default class Lower extends React.Component {
    render() {
        if (this.props.interactive) {
            return (
                <div className="lower" >
                    <div className="verticalLine" ></div>
                    <ClickBead enabled={this.props.display[0]} onClick={()=>this.props.onClick(0, this.props.display[0])} />
                    <ClickBead enabled={this.props.display[1]} onClick={()=>this.props.onClick(1, this.props.display[1])} />
                    <ClickBead enabled={this.props.display[2]} onClick={()=>this.props.onClick(2, this.props.display[2])} />
                    <ClickBead enabled={this.props.display[3]} onClick={()=>this.props.onClick(3, this.props.display[3])} />
                    <ClickBead enabled={this.props.display[4]} onClick={()=>this.props.onClick(4, this.props.display[4])} />
                </div>
            )
        }
        else {
            return (
                <div className="lower" >
                    <div className="verticalLine" ></div>
                    <Bead enabled={this.props.display[0]} dark />
                    <Bead enabled={this.props.display[1]} dark={!this.props.display[4] || !this.props.display[3] || !this.props.display[2]} />
                    <Bead enabled={this.props.display[2]} dark={!this.props.display[4] || !this.props.display[3]} />
                    <Bead enabled={this.props.display[3]} dark={!this.props.display[4]} />
                    <Bead enabled={this.props.display[4]} />
                </div>
            )
        }
    }
}