import React from 'react';

import ClickBead from './ClickBead';
import Bead from './Bead';

import '../../css/AbacusAssets/main.css';

export default class Upper extends React.Component {
    render() {
        if (this.props.interactive)
        {
            return (
                <div className="upper" >
                    <div className="verticalLine"></div>
                    <ClickBead enabled={this.props.display[0]} onClick={this.props.display[0] ? this.props.onClick:undefined} />
                    <ClickBead enabled={this.props.display[1]} onClick={this.props.display[1] ? this.props.onClick:undefined} dark />
                </div>
            )
        }
        else {
            return (
                <div className="upper" >
                    <div className="verticalLine"></div>
                    <Bead enabled={this.props.display[0]}  />
                    <Bead enabled={this.props.display[1]} dark />
                </div>
            )
        }
    }
}