import React from 'react';

import AnimUnit from './AbacusAssets/AnimUnit';
import Unit from './AbacusAssets/Unit'

import '../css/abacus.css';
import '../css/core.css';

export default class AnimAbacus extends React.Component {

    state = {
        currentExecution: 0,
    }

    render() {
        var units = [<Unit enabled key={0} />];

        var startArray, endArray;

        if (this.props.render) {
            units = [];

            startArray = this.props.start ? this.props.start.toString().split("").map(Number) : [0];
            endArray = this.props.end ? this.props.end.toString().split("").map(Number) : [0];
            endArray.reverse();
            startArray.reverse();

            for (var i = 0; i < endArray.length; i++) {
                units.unshift(<AnimUnit key={i} index={i} start={startArray[i]} end={endArray[i]} current={this.state.currentExecution} callback={() => this.incExec()} />)
            }
            for (i = 0; i < this.props.size - units.length;) {
                units.unshift(<AnimUnit key={i} start={0} end={0} current={this.state.currentExecution} callback={() => this.incExec()} />)
            }
        }


        return (
            <div className="abacus noselect b-left mmmmargin" >
                {units}
                <div className="b-right"></div>
            </div>
        )
    }

    incExec() {
        this.setState(prev => {
            return {
                currentExecution: prev.currentExecution + 1
            }
        }, () => {
            if (this.state.currentExecution > this.props.end ? this.props.end.toString().split("").map(Number) : [0] && typeof this.props.callback === "function") this.props.callback();
        });
    }
}