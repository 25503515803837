import React from 'react';

import Unit from './AbacusAssets/Unit';
import ClickUnit from './AbacusAssets/ClickUnit';

import '../css/abacus.css';
import '../css/core.css';

export default class Abacus extends React.Component {
    render() {
        var units = [];

        var newArray;


        if (!this.props.displayArray)
        {
            newArray = this.props.displayNum ? this.props.displayNum.toString().split("").map(Number) : [0]
        }
        else newArray = this.props.displayArray;

        if (this.props.interactive) {
            for (var i = 0; i < this.props.newArray.length; i++) {
                units.push(<ClickUnit key={i} displayNum={newArray[i]} />)
            }
            for (i = 0; i < this.props.size - units.length;) {
                units.unshift(<ClickUnit key={i} displayNum={0} />)
            }
        }
        else {
            for (i = 0; i < newArray.length; i++) {
                units.push(<Unit key={i} displayNum={newArray[i]} />)
            }
            for (i = 0; i < this.props.size - units.length;) {
                units.unshift(<Unit key={i} />)
            }
        }

        return (
            <div className="abacus noselect b-left mmmmargin" >
                {units}
                <div className="b-right"></div>
            </div>
        )
    }
}